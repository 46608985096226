@import "@Static/css/common";
@import "@Static/css/effects";

$bgColor: var(
    --template-header-background-host-theme,
    var(--template-primary-color-host-theme, 0, 0, 0)
);
$textColor: var(--template-header-text-host-theme, 255, 255, 255);

.root {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: fixed;
    width: 100%;
    left: 0;
    right: 0;

    // Some themes with have multiple primary colors
    // This is to enable that if available
    background-color: rgba($bgColor, 0.9);
    backdrop-filter: saturate(180%) blur(20px);
    color: rgb($textColor);
    box-shadow: 0px 2px 4px #00000029;
    z-index: 30;
    padding: 0px 20px;
    min-height: 61px;
}

.titleContainer {
    flex: 1;
}

.title {
    width: fit-content;
    font-size: 24px;
    font-weight: bold;
    padding-right: 20px;

    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-break: break-word;

    justify-content: flex-start;
    margin-left: 0;
    margin-right: auto;

    cursor: pointer;

    &,
    &:hover,
    &:active,
    &:visited {
        color: inherit;
        text-decoration: none;
    }
}
.pageLinks {
    flex: 1;
    display: flex;
    justify-content: center;

    margin: 0;
    height: 100%;
    .link {
        font-size: 15px;
        white-space: pre;
        padding: 22px 7.5px 22px;
        cursor: pointer;
        margin: 0 4px;
        opacity: 0.8;

        text-transform: uppercase;
        letter-spacing: 0.85px;
        font-weight: 600;
        color: inherit;
        text-decoration: none !important;
        &:hover,
        &:focus {
            background-color: rgb($textColor);
            color: rgb($bgColor);
            opacity: 1;
        }
    }
}
.buttonSection {
    flex: 1;
    display: flex;
    justify-content: flex-end;

    margin-left: 0;
    margin-right: auto;

    display: flex;
    flex-direction: row;
    align-items: center;
    .button {
        white-space: pre;
        margin: 0 6px;
        padding: 4px 15px;
        border-radius: 15px;
        border: 2px solid;
        cursor: pointer;
        &,
        &:hover,
        &:active,
        &:visited {
            text-decoration: unset;
            color: unset;
        }
    }
}
.eventListings {
    position: relative;
    line-height: normal;
    display: flex;
    flex-direction: row;
    align-items: center;

    &:hover,
    &:active {
        .eventListingsDropdownContainer {
            display: flex;
        }
    }
}
.eventListingsDropdownContainer {
    border-radius: 8px;
    position: absolute;
    flex-direction: column;
    padding: 15px 0;
    top: 60px;
    width: 200%;
    min-width: fit-content;
    left: -50%;
    box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.2);
    background-color: rgb($textColor);
    color: rgb($bgColor);
    z-index: -20;

    display: none;
    // Uncomment the line below to keep the eventListings open without hovering
    // display: flex;
    &:hover {
        display: flex;
    }
}
.dropdownLink {
    $pad: 28px;
    padding: 8px $pad;
    color: inherit !important;
    text-decoration: none !important;
    transition: 0.15s ease-in all;
    opacity: 1;
    font-weight: 600;
    letter-spacing: 0.85px;
    font-size: 15px;
    position: relative;
    background-color: rgb($textColor);

    &:hover + a {
        $shift: 1/24;
        padding-left: #{$pad * (1 + $shift)};
        padding-right: #{$pad * (1 - $shift)};
    }
    &:hover {
        $shift: 1/3;
        padding-left: #{$pad * (1 + $shift)};
        padding-right: #{$pad * (1 - $shift)};
        background-color: rgba($bgColor, 0.1);
    }
}

.downSymbol {
    font-size: 16px;
}

.iconButton {
    color: white;
    padding: 0 !important;
    display: flex;
    border: none !important;
}

.mobileDropdownContainer {
    @extend .expandY;
}

@media only screen and (max-width: 1025px) {
    .root {
        padding: 12px 20px;
    }
    .titleContainer {
        flex: 2;
    }
    .title {
        font-size: 16px;
        margin-right: auto;
        padding-right: 0px;
        letter-spacing: -1px;
    }
    .buttonSection .button {
        padding: 8px;
        font-size: 14px;
        margin: 0 4px;
        display: flex;
        justify-content: center;
    }
    .mobileDropdownContainer {
        cursor: pointer;
        box-shadow: 0px 2px 4px #00000029;
        opacity: 0.95;
        position: absolute;
        top: 100%;
        width: 100%;
        left: 0;
        right: 0;
        display: flex;
        flex-direction: column;
        background-color: #111111;

        .link {
            letter-spacing: 0.5px;
            font-weight: 600;

            padding: 12px 28px;
            border-top: 1px solid #fff5;
            transition: 0.2s ease-in-out all;
            font-size: 15px;
            color: #eee;
            &:hover {
                color: #fff;
            }
            &.expandLink {
                background-color: #232323;
                padding-left: 40px;
                transform: scaleY(1);
                transform-origin: top center;
                height: unset;
            }
            &.hiddenLink {
                transform: scaleY(0);
                transform-origin: top center;
                height: 0px;
                border-width: 0;
                padding: 0 40px;
            }
        }
    }
}

@media only screen and (max-width: 600px) {
    .notificationsContainer {
        right: -3rem;
    }
}
